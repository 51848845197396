<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-3 my-3">
        <label for=""> Nº d’immatriculation </label>
        <div>
          {{ vehicle.registrationPlate }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Fabricant </label>
        <div>
          {{ vehicle.manufacturerName }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Modèle </label>
        <div>
          {{ vehicle.modelName }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Moteur </label>
        <div>
          {{ vehicle.engineName }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Année de fabrication </label>
        <div>
          {{ vehicle.yearManufacture }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Carburant </label>
        <div>
          {{vehicle.fuelType}}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Kilometers </label>
        <div>
          {{ vehicle.mileage }}{{ ' km' }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Automatic or manual </label>
        <div>
          {{vehicle.color}}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Maximum passenger </label>
        <div>
          {{seatingCapacity}}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Nº de serie</label>
        <div>
          {{vehicle.serialNumber}}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Type </label>
        <div>
          {{ vehicle.type }}
        </div>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Date de 1er mise en circulation </label>
       <div>
        {{ vehicle.firstRegistrationDate }}
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("vehicle", {
      vehicle: "getVehicle",
    }),
  },
  methods: {},
  beforeMount() {
    this.$store.dispatch("vehicle/show", this.$route.params.reference);
  },
};
</script>
